import React from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'ws-scripts/modules/common';
import { setClassNames } from 'ddc-classnames-js';

function HighLightedItem({ imageSrc, title, width, spacing }) {
	const labels = useLabels();
	const placeholder = '//static.dealer.com/images/blank.gif';
	return (
		<figure
			aria-label={labels.get(title)}
			className={setClassNames([
				'services-item',
				'd-flex',
				'flex-column',
				'align-items-center',
				'text-center',
				spacing.item
			])}
			style={{
				width
			}}
		>
			<img
				width={width}
				height={width}
				src={placeholder}
				data-src={imageSrc}
				alt={labels.get(title)}
				className={setClassNames([
					'services-item-image',
					'rounded-circle',
					'img-responsive',
					'lazy-image',
					'dynamic-resize',
					spacing.image
				])}
				// force image width and height which are overrided as default to always show a circle
				style={{
					width,
					height: width
				}}
			/>
			<figcaption>{labels.getHTML(title)}</figcaption>
		</figure>
	);
}

HighLightedItem.propTypes = {
	imageSrc: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	width: PropTypes.number,
	spacing: PropTypes.shape({
		row: PropTypes.string.isRequired,
		item: PropTypes.string.isRequired,
		image: PropTypes.string.isRequired
	})
};

export default HighLightedItem;
