import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';

import Description from './Description';
import OrderedItemIcon from './OrderedItemIcon';

const InlineCardItem = ({
	description,
	imageSrc,
	linkHref,
	linkTarget,
	linkText,
	title,
	displayStyle,
	number
}) => {
	const imagePlaceholder = '//static.dealer.com/images/blank.gif';

	return (
		<li
			className={setClassNames([
				'd-flex',
				'align-items-start',
				'col-sm-12',
				'col-md-4',
				'pl-1'
			])}
		>
			<div className="pt-4 mr-2 mt-2">
				{displayStyle === 'inline-card' ? (
					<img
						alt=""
						src={imagePlaceholder}
						data-src={imageSrc}
						role="presentation"
						width={64}
						height={64}
						className={setClassNames([
							'rounded-circle',
							'lazy-image'
						])}
					/>
				) : (
					<OrderedItemIcon number={number} />
				)}
			</div>
			<Description
				{...{
					title,
					linkText,
					linkHref,
					linkTarget,
					description,
					count: 0
				}}
			/>
		</li>
	);
};

InlineCardItem.propTypes = {
	description: PropTypes.string,
	imageSrc: PropTypes.string.isRequired,
	linkHref: PropTypes.string.isRequired,
	linkTarget: PropTypes.string.isRequired,
	linkText: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	displayStyle: PropTypes.string.isRequired,
	number: PropTypes.number
};

export default InlineCardItem;
