import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { usePrefs, useRequestData } from 'ws-scripts/modules/common';

import { useWidth } from '../utils/useWidth';
import { chunkArray } from '../utils/chunkArray';

import HighLightedItem from './HighLightedItem';

const GRID = {
	sm: [[3], [2, 2], [3, 2], [3, 3], [2, 3, 2], [3, 3, 2], [3, 3, 3]],
	lg: [[3], [4], [5], [3, 3], [4, 3], [4, 4], [5, 4]]
};

const MAX_ITEMS_ROW = {
	sm: 3,
	lg: 5
};

const ITEMS_SPACING = {
	sm: 16,
	lg: 32
};

const HighLightedItems = ({ items = [] }) => {
	const { windowId } = useRequestData();
	const containerRef = React.useRef(null);

	const { maxVisibleListItems, inlineBreakpoint } = usePrefs();

	// Use container width to set the layout
	const [width] = useWidth(containerRef);
	const size = width < parseInt(inlineBreakpoint, 10) ? 'sm' : 'lg';

	const displayedItems = items.slice(0, parseInt(maxVisibleListItems, 10));

	const spacing =
		size === 'lg'
			? {
					row: 'mb-6',
					image: 'mb-4',
					item: 4
			  }
			: {
					row: 'mb-5',
					image: 'mb-3',
					item: 3
			  };

	const itemsWidth = React.useMemo(() => {
		const itemsForWidth =
			displayedItems.length < MAX_ITEMS_ROW[size]
				? displayedItems.length
				: MAX_ITEMS_ROW[size];
		return width / itemsForWidth - ITEMS_SPACING[size];
	}, [width, displayedItems, size]);

	const imageGrid = React.useMemo(() => {
		return chunkArray(displayedItems, [
			...GRID[size][displayedItems.length - 3]
		]);
	}, [displayedItems, size]);

	React.useEffect(() => {
		// Lazy load images if the layout changed
		if (
			window.DDC.unveil &&
			containerRef.current &&
			window.DDC.modules.lazyImage
		) {
			window.DDC.modules.lazyImage.load(containerRef.current);
		}
	}, [size]);

	return (
		<div ref={containerRef} className={`inline-highlight-${size}`}>
			{React.Children.toArray(
				imageGrid.map((serviceRow, indexRow) => {
					return (
						<div
							className={setClassNames([
								'services-row',
								'd-flex',
								'align-content-center',
								'justify-content-center',
								indexRow === imageGrid.length - 1
									? 'mb-0'
									: spacing.row
							])}
						>
							{serviceRow.map((serviceItem, indexItem) => {
								let override = `mx-${spacing.item}`;
								if (!indexItem) {
									override = `mr-${spacing.item} ml-0`;
								} else if (
									indexItem ===
									serviceRow.length - 1
								) {
									override = `ml-${spacing.item} mr-0`;
								}
								return (
									<HighLightedItem
										width={itemsWidth}
										key={`${windowId}--${indexItem.title}`}
										spacing={{
											...spacing,
											item: override
										}}
										{...serviceItem}
									/>
								);
							})}
						</div>
					);
				})
			)}
		</div>
	);
};

HighLightedItems.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			imageSrc: PropTypes.string.isRequired
		})
	).isRequired
};

export default HighLightedItems;
