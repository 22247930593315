import { useState, useEffect, useCallback } from 'react';

export function useWidth(elementRef, defaultValue = null) {
	const [width, setWidth] = useState(defaultValue);

	const updateWidth = useCallback(() => {
		if (elementRef && elementRef.current) {
			setWidth(elementRef.current.getBoundingClientRect().width);
		}
	}, [elementRef]);

	useEffect(() => {
		updateWidth();
		window.addEventListener('resize', updateWidth);
		return () => {
			window.removeEventListener('resize', updateWidth);
		};
	}, [updateWidth]);

	return [width];
}
