import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';

const OrderedItemIcon = ({ number }) => {
	const circleStyle = {
		width: '64px',
		height: '64px',
		border: 'var(--spot-icon-stroke-width) solid var(--spot-icon-stroke-primary-color)',
		lineHeight: 1
	};

	const circleClassList = setClassNames([
		'rounded-circle',
		'd-flex',
		'align-items-center',
		'justify-content-center',
		'flex-column'
	]);

	const numberClassList = setClassNames([
		'text-primary',
		'ddc-font-size-xlarge',
		'd-inline-block'
	]);

	// The large text has extra line space at top
	// This is necessary to align it in the center of circle
	const numberStyle = {
		marginTop: '-4px'
	};

	return (
		<div className={circleClassList} style={circleStyle}>
			<b className={numberClassList} style={numberStyle}>
				{number}
			</b>
		</div>
	);
};

OrderedItemIcon.propTypes = {
	number: PropTypes.number.isRequired
};

export default OrderedItemIcon;
