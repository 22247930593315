import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useWidth } from '../utils/useWidth';

const Media = ({ imageSrc }) => {
	const imageRef = React.useRef();
	const containerRef = React.useRef();
	const [isImageLoaded, setIsImageLoaded] = React.useState(false);
	const placeholder = '//static.dealer.com/images/blank.gif';
	const showCircleBackground =
		isImageLoaded && /\/dealer-services\//.test(imageSrc);

	const onLoad = () => {
		if (!imageRef.current.src.includes(placeholder)) {
			setIsImageLoaded(true);
		}
	};

	const [width] = useWidth(containerRef, 1);

	return (
		<div
			className={setClassNames([
				'w-25',
				'position-relative',
				'd-flex',
				'flex-column',
				'align-content-center',
				'justify-content-center',
				!showCircleBackground ? 'py-4' : null
			])}
			data-testid="Media"
		>
			{showCircleBackground && (
				<div
					className={setClassNames([
						'w-75',
						'position-absolute',
						'center-block-transform',
						'rounded-circle',
						'bg-circle'
					])}
					style={{ paddingTop: '75%' }}
				/>
			)}
			{/* IE11 required min-height to correct flexbox display */}
			<div ref={containerRef} style={{ minHeight: '1px' }}>
				<img
					alt=""
					ref={imageRef}
					onLoad={onLoad}
					src={placeholder}
					data-src={imageSrc}
					role="presentation"
					width={width}
					height={width}
					className="lazy-image dynamic-resize position-relative"
				/>
			</div>
		</div>
	);
};

Media.propTypes = {
	imageSrc: PropTypes.string.isRequired
};

export default Media;
