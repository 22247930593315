import React from 'react';
import PropTypes from 'prop-types';

import StackedItem from './StackedItem';
import StackedReverseItem from './StackedReverseItem';

const StackedItems = ({ items, displayStyle }) => {
	const StackedComponent =
		displayStyle === 'stacked-reverse-rows'
			? StackedReverseItem
			: StackedItem;

	return (
		<ul className={`${displayStyle} list-unstyled m-0`}>
			{React.Children.toArray(
				items.map((item, index) => (
					<StackedComponent
						item={item}
						count={index + 1}
						totalCount={items.length}
					/>
				))
			)}
		</ul>
	);
};

StackedItems.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	displayStyle: PropTypes.string
};

export default StackedItems;
