import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels } from 'ws-scripts/modules/common';
import { useWidth } from '../utils/useWidth';

const StackedReverseItem = ({ item, count }) => {
	const { imageSrc, title, description = 'BLANK' } = item;
	const labels = useLabels();

	const containerRef = React.useRef();
	const [width] = useWidth(containerRef, 1);

	const placeholder = '//static.dealer.com/images/blank.gif';
	const classes =
		count % 2
			? {
					direction: 'flex-row-reverse',
					justify: 'justify-content-end'
			  }
			: {
					direction: 'flex-row',
					justify: 'justify-content-start'
			  };
	return (
		<li className={setClassNames(['mb-lg-7', 'mb-11'])}>
			<figure
				aria-label={labels.get(title)}
				className={setClassNames([
					'services-item',
					'd-flex',
					'flex-wrap',
					'align-items-center',
					'justify-content-center',
					classes.direction
				])}
			>
				<div
					className={setClassNames([
						'services-item-container',
						'd-flex w-50',
						'px-10'
					])}
				>
					<div
						ref={containerRef}
						className={setClassNames([
							'services-item-image-container',
							'd-flex',
							'w-100',
							'rounded-circle',
							'mb-5',
							'mb-lg-0',
							classes.justify
						])}
					>
						<img
							width={width}
							height={width}
							src={placeholder}
							data-src={imageSrc}
							alt={labels.get(title)}
							className={setClassNames([
								'services-item-image',
								'rounded-circle',
								'img-responsive',
								'w-100',
								'align-self-start',
								'lazy-image',
								'dynamic-resize'
							])}
						/>
					</div>
				</div>
				<figcaption className="w-50 px-0 px-md-10 text-center text-md-left">
					<h4 className="display-4">{labels.get(title)}</h4>
					<p>{labels.getHTML(description)}</p>
				</figcaption>
			</figure>
		</li>
	);
};

StackedReverseItem.defaultProps = {
	item: {
		description: 'BLANK'
	}
};

StackedReverseItem.propTypes = {
	item: PropTypes.shape({
		imageSrc: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		description: PropTypes.string
	}),
	count: PropTypes.number.isRequired
};

export default StackedReverseItem;
