import React from 'react';
import PropTypes from 'prop-types';

import InlineCardItem from './InlineCardItem';

const InlineCardItems = ({ items, displayStyle }) => (
	<ul className={`${displayStyle} list-unstyled m-0 d-flex flex-wrap`}>
		{items.map((item, index) => (
			<InlineCardItem
				{...item}
				number={index + 1}
				displayStyle={displayStyle}
				key={`inline_card_item_${item.title}`}
			/>
		))}
	</ul>
);

InlineCardItems.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	displayStyle: PropTypes.string
};

export default InlineCardItems;
