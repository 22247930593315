export const constructURLParams = (
	url = '',
	params = {},
	isScrollToLink = false
) => {
	// If no URL, return back '#', signifying a null link
	if (!url) {
		return '#';
	} else if (!params || !Object.keys(params).length || isScrollToLink) {
		return url;
	}
	const separator = url && url.includes('?') ? '&' : '?';
	const encodedParams = Object.keys(params)
		.map((key) => [key, params[key]].map(encodeURIComponent).join('='))
		.join('&');
	return `${url}${separator}${encodedParams}`;
};
