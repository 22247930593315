import items from './items';
import widgetInfo from './widgetInfo';
import vehicleInfo from './vehicleInfo';

const root = {
	items,
	widgetInfo,
	vehicleInfo
};

export default root;
