import React from 'react';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLabels, usePrefs } from 'ws-scripts/modules/common';

import StackedItems from '../components/StackedItems';
import HighLightedItems from '../components/HighLightedItems';
import InlineCardItems from '../components/InlineCardItems';

const Widget = () => {
	const labels = useLabels();
	const { displayStyle } = usePrefs();
	const items = useSelector((state) => state.items);
	const isComposer = useSelector((state) => state.widgetInfo);

	if (
		!items.length ||
		(displayStyle === 'inline-highlight' && items.length < 3)
	) {
		if (isComposer) {
			return (
				<Alert bsStyle="info">
					<i aria-hidden="true" className="ddc-icon ddc-icon-alert" />
					<div className="alert-content">
						{labels.get(
							items.length
								? 'NOT_ENOUGH_SERVICES_VERBIAGE'
								: 'CLICK_TO_ENABLE_SERVICES_VERBIAGE'
						)}
					</div>
				</Alert>
			);
		}
		return null;
	}
	switch (displayStyle) {
		case 'stacked-rows':
		case 'stacked-reverse-rows':
		default:
			return <StackedItems displayStyle={displayStyle} {...{ items }} />;
		case 'inline-highlight':
			return <HighLightedItems {...{ items }} />;
		case 'inline-card':
		case 'inline-card-ordered':
			return (
				<InlineCardItems displayStyle={displayStyle} {...{ items }} />
			);
	}
};

export default Widget;
