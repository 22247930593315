import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';

import Media from './Media';
import Description from './Description';

const StackedItem = ({ totalCount, count, item = {} }) => {
	const { description, imageSrc, linkHref, linkTarget, linkText, title } =
		item;

	return (
		<li
			className={setClassNames([
				'd-flex',
				totalCount !== count ? 'border-bottom' : null
			])}
			data-testid="StackedItem"
		>
			<Media
				{...{
					imageSrc
				}}
			/>
			<Description
				{...{
					title,
					count,
					linkText,
					linkHref,
					linkTarget,
					description
				}}
			/>
		</li>
	);
};

StackedItem.propTypes = {
	totalCount: PropTypes.number.isRequired,
	count: PropTypes.number.isRequired,
	item: PropTypes.shape({
		description: PropTypes.string,
		imageSrc: PropTypes.string.isRequired,
		linkHref: PropTypes.string.isRequired,
		linkTarget: PropTypes.string.isRequired,
		linkText: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired
	}).isRequired
};

export default StackedItem;
